
import './global.scss';
import { provide } from 'vue';
import useSocket from './hooks/useSocket';

export default {
  setup(): any {
    // socket实例，通过provide向下级组件传递
    let socket: any = useSocket();
    provide('socket', socket);

    return {};
  },
};
