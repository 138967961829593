/*
 * @Description: 视频信息状态管理
 * @Author: hekang
 * @Date: 2022-02-27 17:09:36
 * @LastEditors: hekang
 * @LastEditTime: 2022-03-22 13:45:14
 */
interface videoSrc {
  type: string;
  src: string;
}
interface stateType {
  videoSrc: videoSrc;
  videoTime: number;
  updateVideoTime: () => void; // 更新视频进度
  pauseVideo: () => void; // 暂停视频
  playVideo: () => void; // 播放视频
  [key: string]: any;
}

export default {
  // 开启命名空间
  namespaced: true,
  // 响应组件中的动作
  actions: {},
  // 操作数据
  mutations: {
    // 修改视频源
    updateVideoSrc(state: stateType, value: videoSrc): void {
      state.videoSrc = { ...value };
    },
    // 更新视频进度
    updateVideoTime(state: stateType, value: number): void {
      state.videoTime = value;
    },
    // 更新房主发送同步进度消息的时间
    updateSendTime(state: stateType, value: number): void {
      state.sendTime = value;
    },

    // 更新视频进度
    setUpdateVideoTime(state: stateType, func: () => void): void {
      state.updateVideoTime = func;
    },
    // 暂停视频
    setPauseVideo(state: stateType, func: () => void): void {
      state.pauseVideo = func;
    },
    // 播放视频
    setPlayVideo(state: stateType, func: () => void): void {
      state.playVideo = func;
    },

    // 修改视频同步的误差时间
    updateErrTime(state: stateType, value: number): void {
      state.errTime = value;
    },
  },
  // 存储数据
  state: {
    videoSrc: {
      type: 'video/mp4',
      src: 'https://www.hadesdai.top/img/test.mp4',
      // src: require('@/assets/1.mp4'),
    },
    videoTime: 0, // 房主视频时间
    sendTime: 0, // 房主同时视频时的时间戳
    updateVideoTime: (): void => {}, // 更新视频进度
    pauseVideo: (): void => {}, // 暂停视频
    playVideo: (): void => {}, // 播放视频
    errTime: 60, // 同步误差时间
  },
  // 数据加工
  getters: {},
};
