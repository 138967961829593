
import CryptoJs from 'crypto-js';
import { reactive, toRefs, inject } from 'vue';
import { Dialog, Toast } from 'vant';
import router from '@/router';
export default {
  name: 'SetUserPwd',
  setup() {
    // 接收socket
    const socket: any = inject('socket');
    let data = reactive({
      oldPassword: '',
      newPassword: '',
    });
    // 保存修改
    const onSubmitPwd = (params: any): void => {
      params['userId'] = sessionStorage.getItem('userId') || '';
      params['oldPassword'] = CryptoJs.MD5(params.oldPassword).toString();
      params['newPassword'] = CryptoJs.MD5(params.newPassword).toString();
      socket.emit('setPassword', params, setAccountSuccess);
      Toast.loading({
        duration: 0,
        forbidClick: true, // 禁止单击
        message: '正在修改...',
      });
    };
    const setAccountSuccess = (data: any): void => {
      Toast.clear();
      Dialog.alert({
        title: data.code === 200 ? '成功' : '失败',
        message: data.msg,
      }).then(() => {
        // 用户绑定sid信息
        const utk = localStorage.getItem('utk');
        if (utk) {
          socket.emit('userConnect', { utk }, (data: any) => {
            console.log(data);
            const userInfo = data.data.userInfo;
            sessionStorage.setItem('userId', userInfo.userId || '');
            sessionStorage.setItem('userName', userInfo.userName || '');
            sessionStorage.setItem('avatar', userInfo.avatar || '');
            sessionStorage.setItem('roomId', userInfo.roomId || '');
            sessionStorage.setItem('homeowner', userInfo.homeowner || '');
            sessionStorage.setItem('roomPwd', data.data.roomPwd);
            data.code === 200 && router.back();
          });
        }
      });
    };

    return {
      ...toRefs(data),
      onSubmitPwd,
    };
  },
};
