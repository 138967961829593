/*
 * @Description: socket配置项
 * @Author: hekang
 * @Date: 2022-02-27 12:16:59
 * @LastEditors: hekang
 * @LastEditTime: 2022-07-25 15:41:36
 */
import { Toast } from 'vant';
import { io } from 'socket.io-client';
import { Dialog } from 'vant';
import { onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
interface dataType {
  msgType: string;
  msg: {
    content?: string;
    [key: string]: any;
  };
  [key: string]: any;
}

export default (): any => {
  // 状态
  const store: any = useStore();
  // 创建客户端 websocket 的实例
  // let socket: any = io('http://127.0.0.1:5001'); // 开发
  let socket: any = io('https://video.hadesdai.top/'); // 上线
  onMounted(() => {
    socket.on('connect_error', function (error: any) {
      console.log(error);
    });
    // 初始化连接
    socket.on('connect', () => {
      console.log('服务器连接成功！');
      // 用户绑定sid信息
      const utk = localStorage.getItem('utk');
      if (utk) {
        socket.emit('userConnect', { utk }, (data: any) => {
          console.log(data);
          const userInfo = data.data.userInfo;
          sessionStorage.setItem('userId', userInfo.userId || '');
          sessionStorage.setItem('userName', userInfo.userName || '');
          sessionStorage.setItem('avatar', userInfo.avatar || '');
          sessionStorage.setItem('roomId', userInfo.roomId || '');
          sessionStorage.setItem('homeowner', userInfo.homeowner || '');
          sessionStorage.setItem('roomPwd', data.data.roomPwd);
        });
      }
    });
    // 接收消息
    socket.on('getMsg', (data: dataType): void => {
      if (data.code === 200) {
        store.commit('userInfo/getMsgMut', data.data);
      }
    });
    // 更新房间成员
    socket.on('updateRoomMembers', (data: dataType): void => {
      store.commit('userInfo/updateRoomMembers', data);
    });
    // 关闭房间
    socket.on('closeRoom', (): void => {
      sessionStorage.removeItem('roomId');
      sessionStorage.removeItem('roomPwd');
      sessionStorage.removeItem('homeowner');
      Dialog.alert({
        message: '房间已被房主关闭！',
      }).then(() => {
        store.commit('userInfo/clearMsg');
        router.replace('/');
      });
    });
    // 退出登录
    socket.on('logout', (msg: string): void => {
      sessionStorage.clear();
      localStorage.clear();
      Dialog.alert({
        message: msg,
      }).then(() => {
        store.commit('userInfo/clearMsg');
        router.replace('/');
      });
    });

    // 视频操作
    // 更新视频链接
    socket.on('updateVideoSrc', (videoSrc: any): void => {
      store.commit('videoInfo/updateVideoSrc', videoSrc); // 修改本地视频资源数据
      Toast('房主已更换视频！');
    });
    // 更新视频进度，除房主
    socket.on('updateVideoTime', (data: any): void => {
      if (data.code == 200) {
        // 方案一
        const videoTime: number = data.data.videoTime;
        const sendTime: number = data.data.sendTime;
        // const predictionTime: number = videoTime + (new Date().getTime() / 1000 - sendTime);
        store.commit('videoInfo/updateVideoTime', videoTime); // 房主视频进度时间
        store.commit('videoInfo/updateSendTime', sendTime); // 房主同步视频进度时间

        // 方案二
        // const realTime = store.state.videoInfo.videoTime;
        // if (Math.abs(predictionTime - realTime) > 2) {
        //   store.state.videoInfo.updateVideoTime(predictionTime); // 更新视频进度
        // }
      }
    });
    // 暂停视频
    socket.on('pauseVideo', (): void => {
      store.state.videoInfo.pauseVideo();
      Toast('房主已暂停视频！');
    });
    // 开始播放视频
    socket.on('playVideo', (): void => {
      store.state.videoInfo.playVideo();
      Toast('房主已开始播放视频！');
    });

    // 匹配好友
    socket.on('matchSuccess', (data: any): void => {
      store.commit('userInfo/clearTimer');
      // 成功加入房间回调
      if (data.code === 200) {
        // 设置用户房间信息
        const roomInfo = data.data;
        sessionStorage.setItem('roomId', roomInfo.roomId);
        sessionStorage.setItem('roomPwd', roomInfo.roomPwd);
        sessionStorage.setItem('homeowner', roomInfo.homeowner);
      }
      router.replace('/TogetherVideo');
    });
  });
  // 组件被销毁之前，清空 sock 对象
  onBeforeUnmount(() => {
    console.log('卸载组件');
    socket.close();
    socket = null;
  });

  return socket;
};
