
import { useStore } from 'vuex';
import { reactive, toRefs, inject, onBeforeUnmount } from 'vue';
import { Dialog } from 'vant';
import router from '@/router';
import useCheckUtk from '@/hooks/useCheckUtk';
export default {
  name: 'MatchFriends',
  components: {},
  setup(): any {
    const socket: any = inject('socket');
    // 状态
    const store: any = useStore();
    let data = reactive({
      timer: 0,
      matchState: false,
      btnContent: '开始匹配',
      pwd: null,
    });
    // 切换匹配状态开始/结束
    const changeMatchState = (): void => {
      if (!data.matchState) {
        // 开始匹配
        const roomId = sessionStorage.getItem('roomId') || '';
        if (!roomId) {
          console.log('开始匹配');
          data.matchState = true;
          data.btnContent = data.matchState ? '停止' : '开始匹配';
          // 房间不存在，开启定时器
          store.commit(
            'userInfo/addTImer',
            setInterval(() => {
              console.log('匹配中');
              socket.emit('matchFriends'); // 匹配小伙伴
            }, 5000)
          );
        } else {
          Dialog.alert({
            message: '您已存在房间，请先退出！',
          }).then(() => {
            router.back();
          });
        }
      } else {
        // 结束匹配
        clearTimer(); // 清除定时器
        router.back();
      }
    };
    // 停止匹配
    const clearTimer = (): void => {
      socket.emit('cancelMatch'); // 取消匹配小伙伴
      store.commit('userInfo/clearTimer'); // 清除定时器
    };
    // 登陆超时监测
    useCheckUtk();
    onBeforeUnmount(() => {
      clearTimer(); // 清除定时器
    });
    // 退出
    const back = (): void => {
      router.back();
    };
    return { ...toRefs(data), changeMatchState, back };
  },
};
