
import { ref, onMounted, onUpdated } from 'vue';
import GradientBox from '@/components/GradientBox/index.vue';
import msgBubble from './components/msgBubble/index.vue';
export default {
  name: 'ChatBox',
  components: { msgBubble, GradientBox },
  props: {
    msgData: {
      type: Array,
      default: null,
    },
  },
  setup(): any {
    // 末尾消息ref
    const endMsgRef: any = ref(null);
    // 聚焦最新的消息
    const focusEndMsg = () => {
      const msgList = endMsgRef.value.children;
      const endMsg = msgList[msgList.length - 1];
      endMsg &&
        endMsg.scrollIntoView({
          behavior: 'smooth', // 平滑过渡
          block: 'end', // 视口的底部和元素底部对齐
        });
    };
    // 更新聊天框
    onMounted(() => focusEndMsg());
    onUpdated(() => focusEndMsg());
    return { endMsgRef };
  },
};
