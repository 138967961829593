/*
 * @Description: 格式化时间
 * @Author: hekang
 * @Date: 2022-03-03 15:12:41
 * @LastEditors: hekang
 * @LastEditTime: 2022-03-03 15:18:25
 */
interface objType {
	[key: string]: any;
}

/*
let date = new Date()
dateFormat("YYYY/mm/dd HH:MM:SS", date)
 */
const dateFormat = (fmt: string, date: Date): string => {
	let ret;
	const opt: objType = {
		'Y+': date.getFullYear().toString(), // 年
		'm+': (date.getMonth() + 1).toString(), // 月
		'd+': date.getDate().toString(), // 日
		'H+': date.getHours().toString(), // 时
		'M+': date.getMinutes().toString(), // 分
		'S+': date.getSeconds().toString(), // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (const k in opt) {
		ret = new RegExp('(' + k + ')').exec(fmt);
		if (ret) {
			fmt = fmt.replace(
				ret[1],
				ret[1].length == 1
					? opt[k]
					: opt[k].padStart(ret[1].length, '0')
			);
		}
	}
	return fmt;
};

export { dateFormat };
