import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.selectedData,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedData) = $event)),
      "is-link": "",
      readonly: "",
      label: $props.label,
      placeholder: $props.placeholder,
      onClick: $props.changeShowState
    }, null, 8, ["modelValue", "label", "placeholder", "onClick"]),
    _createVNode(_component_van_popup, {
      show: $setup.isShowSelect,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.isShowSelect) = $event)),
      round: "",
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_picker, {
          columns: $props.selectList,
          onCancel: $props.changeShowState,
          onConfirm: $setup.selected
        }, null, 8, ["columns", "onCancel", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}