/*
 * @Description: 用户信息状态管理
 * @Author: hekang
 * @Date: 2022-02-27 17:09:36
 * @LastEditors: hekang
 * @LastEditTime: 2022-03-23 13:04:25
 */
interface stateType {
  msgList: any[];
  roomMembers: any[];
  [key: string]: any;
}
interface objType {
  [key: string]: any;
}

export default {
  // 开启命名空间
  namespaced: true,
  // 响应组件中的动作
  actions: {},
  // 操作数据
  mutations: {
    // 接收消息
    getMsgMut(state: stateType, value: objType): void {
      value.msgType = true;
      state.msgList.push(value);
    },
    // 发送消息
    sendMsg(state: stateType, value: objType): void {
      state.msgList.push(value);
    },
    // 成功发送消息后更新状态
    sendMsgSuccess(state: stateType, value: objType): void {
      // 逆序遍历，改变消息发送状态
      for (let index = state.msgList.length - 1; index > -1; index--) {
        if (state.msgList[index].msgId === value.msgId) {
          state.msgList[index].sendState = true;
          break;
        }
      }
    },
    // 清空消息
    clearMsg(state: stateType): void {
      state.msgList = [];
    },
    // 房间成员处理
    updateRoomMembers(state: stateType, value: any[]): void {
      state.roomMembers = value;
    },

    // 添加定时器
    addTImer(state: stateType, value: number): void {
      state.matchTImer = value;
    },
    // 清除定时器
    clearTimer(state: stateType): void {
      state.matchTImer && clearInterval(state.matchTImer);
      state.matchTImer = 0;
    },
  },
  // 存储数据
  state: {
    roomMembers: [],
    msgList: [],
    matchTImer: 0,
  },
  // 数据加工
  getters: {},
};
