
import { inject, reactive, toRefs, ref } from 'vue';
import { useStore } from 'vuex';
import { dateFormat } from '@/util/formatTime';
import router from '@/router';
import { Toast } from 'vant';
export default {
  name: 'MsgInputBox',
  setup(): any {
    let data = reactive({
      content: '',
    });
    // 消息发送框ref
    let inputRef: any = ref(null);
    // 状态管理
    const store = useStore();
    // 接收socket
    const socket: any = inject('socket');
    // 发送消息
    const sendMsg = (): void => {
      if (!sessionStorage.getItem('roomId')) {
        router.go(0);
        Toast('房间已过期');
      }
      console.log('client: 消息发送...');
      const nowTime = new Date();
      const msgId = parseInt(sessionStorage.getItem('userId') || '1') + nowTime.getTime();
      const msg = {
        msgId: msgId,
        userId: sessionStorage.getItem('userId') || -1,
        userName: sessionStorage.getItem('userName') || '',
        avatar: sessionStorage.getItem('avatar') || '',
        content: data.content,
        msgTime: dateFormat('YYYY-mm-dd HH:MM:SS', nowTime),
        msgType: false,
        sendState: false,
        homeowner: sessionStorage.getItem('homeowner') === '1',
      };
      // 后台请求
      socket.emit('sendMsg', msg, (data: any) => store.commit('userInfo/sendMsgSuccess', data.data));
      // 前端渲染
      store.commit('userInfo/sendMsg', msg);
      // 清空数据
      data.content = '';
      // 聚焦输入框
      inputRef.value.focus();
    };
    return { ...toRefs(data), sendMsg, inputRef };
  },
};
