
export default {
  name: 'OptionCard',
  props: {
    imgUrl: {
      type: String,
      required: true,
    },
  },
};
