
import { computed, onMounted } from 'vue';
import router from '@/router';
import { useStore } from 'vuex';
import { Toast } from 'vant';
import MyVideoPlayer from '@/components/MyVideoPlayer/index.vue';
import ChatBox from '@/components/ChatBox/index.vue';
import MsgInputBox from './components/MsgInputBox/index.vue';
import RoomTools from './components/RoomTools/index.vue';
import useCheckUtk from '@/hooks/useCheckUtk';
export default {
  name: 'VideoRoom',
  components: { MyVideoPlayer, ChatBox, MsgInputBox, RoomTools },
  setup(): any {
    // 状态
    const store = useStore();
    // 获取消息列表
    let msgData = computed(() => store.state.userInfo.msgList);
    const goBack = () => {
      router.back();
    };

    // 登陆超时监测
    useCheckUtk();

    // 房间确认是否存在（延迟执行，等待连接操作后更新roomId）
    const roomState = (): void => {
      const toast: any = Toast.loading({
        duration: 0,
        message: '正在进入房间3s',
        forbidClick: true,
      });
      let second = 3;
      const timer = setInterval(() => {
        second--;
        if (second) {
          toast.message = !sessionStorage.getItem('roomId') ? `房间已过期` : `正在进入房间${second}s`;
        } else {
          clearInterval(timer);
          Toast.clear();
          sessionStorage.getItem('roomId') || router.push('JoinRoom');
        }
      }, 1000);
    };
    // 初始化
    onMounted(() => {
      // 判断用户是否存在房间
      roomState();
    });
    return { msgData, goBack };
  },
};
