
import CryptoJs from 'crypto-js'; // 加解密插件
import router from '@/router';
import { reactive, toRefs, inject, defineComponent } from 'vue';
import { Toast } from 'vant';
import ImgUploader from '@/components/ImgUploader/index.vue';
export default defineComponent({
  name: 'Register',
  components: { ImgUploader },
  setup(): any {
    let data = reactive({
      accountName: '',
      password: '',
      confirmPassword: '',
      verificationCode: '',
      verificationShow: false,
      livingCode: 0,
      registerToast: null,
      emailSendState: 0, // 邮件发送状态，0. 未请求 1. 请求发送 2. 请求成功回调
      emailTip: '发送验证码', // 邮件发送按钮内容
      imgList: [], // 头像列表
    });
    // 接收socket
    const socket: any = inject('socket');
    // 返回
    const goBack = (): void => {
      router.back();
    };
    // 注册
    const onSubmit = (formData: any): void => {
      const imgFile: any = data.imgList[0];
      const params = {
        accountName: formData.accountName, // 账号
        password: CryptoJs.MD5(formData.password).toString(), // 密码
        verificationCode: formData.verificationCode, // 邮箱验证码
        avatar: imgFile?.content || '', // 头像
      };
      socket.emit('registerAccount', params, registerSuccess);
      data.registerToast = Toast.loading({
        duration: 0,
        forbidClick: true, // 禁止单击
        message: '正在注册...',
      }) as any;
    };
    // 注册账户回调
    const registerSuccess = (data: any): void => {
      // 清除弹窗
      Toast.clear();
      data.registerToast = null;
      if (data.code === 200) {
        router.push('Login');
      } else {
        router.go(0);
      }
      Toast(data.msg);
    };
    // 发送邮件验证
    const authEmail = (): void => {
      if (!data.accountName) {
        Toast('邮箱信息有误！');
        return;
      }
      data.emailSendState = 1; // 邮箱验证请求发送中
      socket.emit('authEmail', { email: data.accountName }, sendEmailResult);
    };
    // 邮件发送回调
    const sendEmailResult = (data: any): void => {
      emailTimer(60); // 邮箱验证定时器
      Toast(data.msg); // 回调通知
    };
    // 邮件验证定时器
    const emailTimer = (second: number): void => {
      data.emailSendState = 2; // 邮件发送请求成功回调
      const timer: any = setInterval(() => {
        data.emailTip = second.toString() + 's' + ' 后可重新发送';
        if (second <= 0) {
          clearInterval(timer);
          data.emailTip = '发送邮件';
          data.emailSendState = 0;
        }
        second--;
      }, 1000);
    };
    // 用户头像图片类型限制
    const imgTypeRestriction = (file: any) => {
      if (['image/jpeg', 'image/png'].indexOf(file.file.type) == -1) {
        Toast('请上传 jpg/png 格式图片');
        data.imgList = [];
        return false;
      }
      return true;
    };
    // 用户头像图片大小控制
    const imgOversize = () => {
      Toast('文件大小不能超过 1m');
    };
    // 设置图片列表数据
    const setImgList = (imgList: []): void => {
      data.imgList = imgList;
    };
    return { ...toRefs(data), goBack, onSubmit, authEmail, imgTypeRestriction, imgOversize, setImgList };
  },
});
