
import { onBeforeMount, reactive, toRefs } from 'vue';
import router from '@/router';
export default {
  name: 'UserInfoCell',
  setup() {
    let data = reactive({
      userName: '',
      userId: '',
      avatar: '',
    });
    // 跳转修改用户信息页面
    const goSetPersonalData = (): any => router.push('/PersonalCenter/SetPersonalData');
    // 获取用户数据
    const setUserInfo = (): void => {
      data.userName = sessionStorage.getItem('userName') || '';
      data.userId = sessionStorage.getItem('userId') || '';
      data.avatar = sessionStorage.getItem('avatar') || '';
    };
    onBeforeMount(() => setUserInfo());
    return { ...toRefs(data), goSetPersonalData };
  },
};
