import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "video" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_video_player, {
      class: "video-player vjs-custom-skin",
      ref: "videoRef",
      playsinline: true,
      options: $setup.data.playerOptions,
      onPlay: _cache[0] || (_cache[0] = ($event: any) => ($setup.onPlayerPlay(_ctx.event))),
      onPause: _cache[1] || (_cache[1] = ($event: any) => ($setup.onPlayerPause($event))),
      onTimeupdate: _cache[2] || (_cache[2] = ($event: any) => ($setup.onPlayerTimeupdate($event))),
      onReady: $setup.playerReadied
    }, null, 8, ["options", "onReady"])
  ]))
}