import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_nav_bar, {
      "safe-area-inset-top": "",
      title: _ctx.title,
      "left-text": "",
      "left-arrow": "",
      onClickLeft: $setup.goBack
    }, null, 8, ["title", "onClickLeft"]),
    _createVNode(_component_router_view)
  ], 64))
}