
import { reactive, toRefs, computed } from 'vue';
export default {
  name: 'SelectPopup',
  props: {
    // label显示内容
    label: {
      type: String,
      default: '选择',
    },
    // 提示
    placeholder: {
      type: String,
      default: '默认选择',
    },
    // 展示状态：显示/隐藏
    showState: {
      type: Boolean,
      required: true,
    },
    // 改变展示状态
    changeShowState: {
      type: Function,
      required: true,
    },
    // 选项列表
    selectList: {
      type: Array,
      default: (): any[] => ['1', '2', '3'],
    },
    // 提交时获取数据
    getSelectData: {
      type: Function,
      default: (): void => {},
    },
  },

  setup(props: any): any {
    let data = reactive({
      selectedData: '', // 当前选中数据
    });
    // 展示状态
    const isShowSelect = computed({
      get() {
        return props.showState; // 读取传入状态
      },
      set() {
        return props.changeShowState(); // 修改状态
      },
    });
    // 选中
    const selected = (selectedData: any): void => {
      typeof selectedData === 'object' && (selectedData = selectedData.join(' '));
      data.selectedData = selectedData;
      props.changeShowState();
      props.getSelectData(data.selectedData);
    };
    return { ...toRefs(data), selected, isShowSelect };
  },
};
