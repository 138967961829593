/*
 * @Description: vuex状态管理
 * @Author: hekang
 * @Date: 2022-02-25 13:42:56
 * @LastEditors: hekang
 * @LastEditTime: 2022-03-06 10:26:28
 */
import { createStore } from 'vuex';
import userInfo from './modules/userInfo';
import videoInfo from './modules/videoInfo';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userInfo,
    videoInfo,
  },
  strict: true, // 严格模式，必须经过mutation函数
});
