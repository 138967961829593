/*
 * @Description: 个人中心菜单数据
 * @Author: hekang
 * @Date: 2022-02-28 16:44:03
 * @LastEditors: hekang
 * @LastEditTime: 2022-07-25 12:32:28
 */
const toolGroup = {
  title: '功能',
  cellList: [
    // {
    //   title: '后台权限',
    //   to: '/PersonalCenter/SetPermissions',
    //   replace: false,
    //   icon: 'shield-o',
    // },
    // {
    //   title: '通知设置',
    //   to: '/PersonalCenter/SetNotification',
    //   replace: false,
    //   icon: 'setting-o',
    // },
    {
      title: '联系我们',
      to: '/PersonalCenter/ContactUs',
      replace: false,
      icon: 'https://video.hadesdai.top/static/materialPicture/service.png',
    },
    // {
    //   title: '反馈意见',
    //   to: '/PersonalCenter/Feedback',
    //   replace: false,
    //   icon: 'comment-circle-o',
    // },
    {
      title: '关于',
      to: '/PersonalCenter/About',
      replace: false,
      icon: 'https://video.hadesdai.top/static/materialPicture/about.png',
    },
  ],
};

export { toolGroup };
