
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { reactive, toRefs, inject, computed, onUpdated, toRaw, nextTick } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import SelectPopup from '@/components/SelectPopup/index.vue';
import copyToClipboard from '@/util/copyToClipboard';

export default {
  name: 'RoomTools',
  components: { SelectPopup },
  setup(): any {
    let data = reactive({
      isShowTools: false,
      isShowMembers: false,
      isHomeowner: false,
      isShowSelectVideo: false,
      isShowSelectType: false,
      videoTypeList: ['视频解析', 'application/x-mpegURL', 'video/mp4', 'video/webm'],
      videoSrc: {
        type: '',
        src: '',
      },
      leaveToast: null, // 离开房间加载等待
      // 视频配置
      isShowConfig: false,
      isShowSelectErrTime: false,
      errTime: 0, // 同步误差时间
      errTImeList: [
        {
          values: [...Array(61)].map((v, i) => i.toString()),
          defaultIndex: 1,
        },
        {
          values: ['秒', '分'],
          defaultIndex: 1,
        },
      ],
    });
    // 状态
    const store: any = useStore();
    // 获取房间成员列表
    let roomMembers = computed(() => store.state.userInfo.roomMembers);
    // 接收socket
    const socket: any = inject('socket');
    // 显示/隐藏工具栏
    const showToolFrame = (): void => {
      data.isShowTools = !data.isShowTools;
    };
    // 显示/隐藏房间信息
    const showMembers = (): void => {
      data.isShowMembers = !data.isShowMembers;
    };
    // 显示/隐藏视频选择
    const showSelectVideo = (): void => {
      data.isShowSelectVideo = !data.isShowSelectVideo;
    };
    // 显示/隐藏选择视频类型
    const showSelectType = (): void => {
      data.isShowSelectType = !data.isShowSelectType;
    };
    // 更换换视频
    const changeVideo = (): void => {
      if (data.videoSrc.type && data.videoSrc.src) {
        // if (data.videoSrc.type === '视频解析') {
        //   Toast('功能开发中');
        //   return;
        // }
        const videoSrc = toRaw(data.videoSrc);
        showSelectVideo(); // 关闭输入框
        socket.emit('updateVideoSrc', { videoSrc: videoSrc }); // 更新后端数据
      } else {
        Toast('缺少必填项');
      }
    };
    // 显示/隐藏视频配置
    const showConfig = (): void => {
      data.isShowConfig = !data.isShowConfig;
    };
    // 修改误差时间
    const getErrorTime = (theData: string): void => {
      const selectedData: string[] = theData.split(' ');
      const tempTime: number = parseInt(selectedData[0]);
      const errTIme: number = selectedData[1] === '秒' ? tempTime : tempTime * 60;
      data.errTime = errTIme;
    };
    // 提交配置
    const configVideo = (): void => {
      data.isShowConfig = false;
      store.commit('videoInfo/updateErrTime', data.errTime);
      Toast(data.errTime === 0 ? '已关闭自动同步房主进度' : `视频同步误差已设置${data.errTime}s`);
    };
    // 离开房间
    const leaveRoom = (): void => {
      Dialog.confirm({
        message: '您确定要退出房间吗？',
      })
        .then(() => {
          socket.emit('leaveRoom', leaveRoomSuccess);
          store.commit('userInfo/clearMsg');
          data.leaveToast = Toast.loading({
            duration: 0,
            forbidClick: true, // 禁止单击
            message: '正在退出房间',
          }) as any;
        })
        .catch(() => {});
    };
    // 退出房间
    const closeRoom = (): void => {
      Dialog.confirm({
        message: '您确定要退出房间吗？',
      })
        .then(() => {
          socket.emit('closeRoom', leaveRoomSuccess);
          data.leaveToast = Toast.loading({
            duration: 0,
            forbidClick: true, // 禁止单击
            message: '正在退出房间',
          }) as any;
        })
        .catch(() => {});
    };
    // 成功离开/退出房间回调
    const leaveRoomSuccess = (): void => {
      // 清除加载图标
      Toast.clear();
      data.leaveToast = null;
      // 清除用户信息
      sessionStorage.removeItem('roomId');
      sessionStorage.removeItem('roomPwd');
      sessionStorage.removeItem('homeowner');
      store.commit('userInfo/clearMsg');
      // 清除视频链接
      const videoSrc = {
        type: 'video/mp4',
        src: 'https://www.hadesdai.top/img/test.mp4',
      };
      store.commit('videoInfo/updateVideoSrc', videoSrc); // 修改本地视频资源数据
      router.back();
      Toast('退出房间成功！');
    };
    // 更新房主状态
    onUpdated(() => (data.isHomeowner = sessionStorage.getItem('homeowner') === '1'));
    // 分享房间
    const shareRoom = (): void => {
      // 复制房间信息至剪切板
      let roomId = sessionStorage.getItem('roomId');
      let roomPwd = sessionStorage.getItem('roomPwd');
      let userName = sessionStorage.getItem('userName');
      // let copyContent = `【一起吧】 http://localhost:8080/#/?roomId=${roomId}&roomPwd=${roomPwd} “${userName}”邀请您加入房间「房间号:${roomId} 密码:${roomPwd}」点击链接直接加入房间哦~`;
      let copyContent = `【一起吧】 https://video.hadesdai.top/#/?roomId=${roomId}&roomPwd=${roomPwd} “${userName}”邀请您加入房间「房间号:${roomId} 密码:${roomPwd}」点击链接直接加入房间哦~`;
      copyToClipboard(copyContent); // 复制
      Dialog.alert({
        title: '房间信息已复制，快去粘贴吧~',
      }).then(() => {});
    };

    return {
      ...toRefs(data),
      showToolFrame,
      leaveRoom,
      closeRoom,
      showMembers,
      roomMembers,
      shareRoom,
      showSelectVideo,
      showSelectType,
      changeVideo,
      showConfig,
      configVideo,
      getErrorTime,
    };
  },
};
