import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-636b3fb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!

  return (_openBlock(), _createBlock(_component_van_cell_group, {
    class: "cellGroup",
    title: $props.title
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.cellList, (cell, key) => {
        return (_openBlock(), _createBlock(_component_van_cell, {
          key: key,
          "is-link": "",
          to: cell.to,
          replace: cell.replace
        }, {
          title: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_van_icon, {
                name: cell.icon,
                size: "20"
              }, null, 8, ["name"]),
              _createElementVNode("span", null, _toDisplayString(cell.title), 1)
            ])
          ]),
          _: 2
        }, 1032, ["to", "replace"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}