import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd3feccc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserInfoCell = _resolveComponent("UserInfoCell")!
  const _component_CellGroup = _resolveComponent("CellGroup")!
  const _component_AccountCellGroup = _resolveComponent("AccountCellGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UserInfoCell),
    _createVNode(_component_CellGroup, {
      title: $setup.toolGroup.title,
      cellList: $setup.toolGroup.cellList
    }, null, 8, ["title", "cellList"]),
    _createVNode(_component_AccountCellGroup)
  ]))
}