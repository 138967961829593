
import router from '@/router';
import { defineComponent } from '@vue/runtime-core';
import { onMounted } from 'vue';

// 类型声明
type joinRoomInfoType = {
  roomId?: number;
  roomPwd?: number;
};

export default defineComponent({
  name: 'Welcome',
  components: {},
  setup() {
    onMounted(() => {
      // 读取待加入房间信息
      const joinRoomInfo: joinRoomInfoType = router.currentRoute.value.query;
      if (joinRoomInfo.roomId && joinRoomInfo.roomPwd) {
        sessionStorage.setItem('joinRoomInfo', JSON.stringify(joinRoomInfo));
      }
    });

    // 检测是否存在token
    if (localStorage.getItem('utk')) {
      router.push('Home');
    }
    return {};
  },
});
