
import { useStore } from 'vuex';
import { reactive, computed, ref, inject, onBeforeUnmount, onMounted } from 'vue';
import { Toast } from 'vant';
export default {
  /**生命周期
   * 正常播放: ready => loadeddata => canplay/canplaythrough => play => playing => timeupdate => ended
   * 暂停: pause
   * 拖动: waiting => canplay/canplaythrough
   * 缓存: waiting
   * 状态改变: statechanged
   */
  name: 'MyVideoPlayer',
  setup(): any {
    let store = useStore();
    let videoSrc = computed(() => store.state.videoInfo.videoSrc.src);
    let videoType = computed(() => store.state.videoInfo.videoSrc.type);
    let videoRef: any = ref(null);
    // 接收socket
    const socket: any = inject('socket');
    let data = reactive({
      // 配置项
      playerOptions: {
        // playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: videoType,
            src: videoSrc,
          },
        ], // 视频类型和地址
        poster: 'https://www.hadesdai.top/img/video_img.jpg', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      homeownerTimer: null, // 房主定时器，时间变化触发
      membersTimer: null, // 成员定时器
      isFullscreen: false,
      playState: false,
    });
    // 视频同步误差时间
    const errTime: any = computed(() => store.state.videoInfo.errTime);
    // 开始
    const onPlayerPlay = (): void => {
      data.playState = true;
      // 房主请求播放视频
      if (sessionStorage.getItem('homeowner') !== '1') return;
      socket.emit('updateVideoTime', { msgType: 'playVideo' }); // 播放视频
      // 房主更新视频进度数据
      const sendTime = new Date().getTime() / 1000; // 用于校正消息发送延迟
      socket.emit('updateVideoTime', { msgType: 'updateVideoTime', videoTime: 0, sendTime });
    };
    // 暂停
    const onPlayerPause = (): void => {
      data.playState = false;
      if (sessionStorage.getItem('homeowner') !== '1') return;
      socket.emit('updateVideoTime', { msgType: 'pauseVideo' }); // 更新本地视频进度状态
    };
    // 时间更新触发
    const onPlayerTimeupdate = (player: any): any => {
      // 房主同步视频时间
      if (!data.homeownerTimer) {
        data.homeownerTimer = setTimeout(() => {
          const currentTime = player.cache_.currentTime; // 放在定时器内，否则误差
          if (sessionStorage.getItem('homeowner') === '1') {
            // 房主更新视频进度数据
            const sendTime = new Date().getTime() / 1000; // 用于校正消息发送延迟
            socket.emit('updateVideoTime', { msgType: 'updateVideoTime', videoTime: currentTime, sendTime });
          }
          data.homeownerTimer = null;
        }, 3000) as any;
      }
      // 成员定时器，同步房主时间
      if (data.membersTimer || errTime.value === 0) return;
      data.membersTimer = setTimeout(() => {
        console.log('同步');
        const currentTime = player.cache_.currentTime; // 放在定时器内，否则误差
        if (data.playState) {
          const videoTime: number = store.state.videoInfo.videoTime;
          const sendTime: number = store.state.videoInfo.sendTime; // 刚开始未同步
          if (sessionStorage.getItem('homeowner') !== '1' && sendTime > 0) {
            const err = new Date().getTime() / 1000 - sendTime;
            const predictionTime: number = videoTime + err; // 根据网络延迟，计算预计时间
            // console.log('误差', err, ' 预计: ', predictionTime, ' 当前: ', currentTime);
            if (Math.abs(predictionTime - currentTime) > errTime.value) {
              player.currentTime(predictionTime);
              Toast('已同步房主进度！');
            }
          }
        }
        data.membersTimer = null;
      }, 10000) as any;
    };
    // 将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数
    const playerReadied = (player: any): any => {
      // 视频流请求添加header
      // player.tech({ IWillNotUseThisInPlugins: true }).hls;
      // player.tech_.hls.xhr.beforeRequest = function (options: any) {
      //   console.log(options);
      //   options.headers = { 'test-header': 'test' };
      //   return options;
      // };
    };
    // 组件销毁时关闭定时器
    onBeforeUnmount(() => {
      console.log('销毁定时器');
      data.homeownerTimer = null;
      data.membersTimer = null;
    });
    // 额外功能
    // 全屏
    // const changePlayState = (): void => {
    //   data.isFullscreen ? videoRef.value.player.exitFullscreen() : videoRef.value.player.requestFullscreen();
    //   data.isFullscreen = !data.isFullscreen;
    // };

    // 视频控制方法
    onMounted(() => {
      // console.log(videoRef.value.player);
      // 更新视频进度
      const updateVideoTime = (videoTime: number): void => {
        videoRef.value.player.currentTime(videoTime);
      };
      // 暂停视频
      const pauseVideo = (): void => {
        videoRef.value.player.pause();
      };
      // 播放视频
      const playVideo = (): void => {
        videoRef.value.player.play();
      };
      // 将方法存入vuex状态中对外进行暴露
      store.commit('videoInfo/setUpdateVideoTime', updateVideoTime);
      store.commit('videoInfo/setPauseVideo', pauseVideo);
      store.commit('videoInfo/setPlayVideo', playVideo);
    });

    return {
      videoRef,
      data,
      onPlayerPlay,
      onPlayerPause,
      onPlayerTimeupdate,
      playerReadied,
    };
  },
};
