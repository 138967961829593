/*
 * @Description: 复制内容之剪切板
 * @Author: hekang
 * @Date: 2022-07-20 16:22:46
 * @LastEditors: hekang
 * @LastEditTime: 2022-07-20 16:23:24
 */

const copyToClipboard = (content: string) => {
  // content为要复制的内容
  // 创建元素用于复制
  const ele = document.createElement('input');
  // 设置元素内容
  ele.setAttribute('value', content);
  // 将元素插入页面进行调用
  document.body.appendChild(ele);
  // 复制内容
  ele.select();
  // 将内容复制到剪贴板
  const copyState = document.execCommand('copy');
  // 删除创建元素
  document.body.removeChild(ele);
  return copyState ? '复制成功' : '复制失败';
};
export default copyToClipboard;
