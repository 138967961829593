import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e4edcf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "userName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "userInfo",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.goSetPersonalData && $setup.goSetPersonalData(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.userName), 1)
  ]))
}