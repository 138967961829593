
import router from '@/router';
import useCheckUtk from '@/hooks/useCheckUtk';
import { onBeforeUpdate, onMounted, reactive, toRefs } from 'vue';
export default {
  name: 'PersonalCenter',
  setup(): any {
    let data = reactive({
      title: '',
    });
    // 返回上一页
    const goBack = () => {
      router.back();
    };
    // 标题枚举
    const selectTitle = (pageName: string): string => {
      let titleList: { [key: string]: string } = {
        PersonalCenter: '个人中心',
        About: '关于',
        ContactUs: '联系我们',
        Feedback: '意见反馈',
        SetNotification: '通知设置',
        SetPermissions: '应用权限',
        SetPersonalData: '修改用户信息',
        SetUserPwd: '修改密码',
      };
      return titleList?.[pageName] || '';
    };
    // 设置标题
    const setTitle = () => {
      const currentRoute: any = router.currentRoute;
      const path = currentRoute._value.fullPath;
      const pageName: any = path.split('/').pop();
      data.title = selectTitle(pageName);
    };
    // 登陆超时监测
    useCheckUtk();
    // 导航栏标题
    onBeforeUpdate(() => setTitle());
    onMounted(() => setTitle());
    return { ...toRefs(data), goBack };
  },
};
