/*
 * @Description:
 * @Author: hekang
 * @Date: 2022-02-25 13:42:56
 * @LastEditors: hekang
 * @LastEditTime: 2022-03-21 11:11:25
 */
import { createApp } from 'vue';
import {
  Button,
  Icon,
  NavBar,
  Image as VanImage,
  Cell,
  CellGroup,
  Field,
  Overlay,
  Form,
  Popup,
  Toast,
  Picker,
  Tag,
  PasswordInput,
  NumberKeyboard,
  Loading,
  Dialog,
  Uploader,
} from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
// 视频播放器
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
import hls from 'videojs-contrib-hls';

const app = createApp(App);
app.use(store);
app.use(router);
// ant 组件注册
app.use(Button);
app.use(Icon);
app.use(NavBar);
app.use(VanImage);
app.use(Cell);
app.use(CellGroup);
app.use(Field);
app.use(Overlay);
app.use(Form);
app.use(Popup);
app.use(Toast);
app.use(Picker);
app.use(Tag);
app.use(PasswordInput);
app.use(NumberKeyboard);
app.use(Loading);
app.use(Dialog);
app.use(Uploader);
// 视频播放器
app.use(VideoPlayer);
app.use(() => hls); // 解析m3u8

app.mount('#app');
