/*
 * @Description: 监测用户是否登陆超时
 * @Author: hekang
 * @Date: 2022-03-21 19:11:33
 * @LastEditors: hekang
 * @LastEditTime: 2022-03-21 20:15:30
 */
import router from '@/router';
import { Dialog } from 'vant';
import { onMounted } from 'vue';
import { inject } from 'vue';

export default (): any => {
  // 接收socket
  const socket: any = inject('socket');
  // 登录监测
  const checkResult = (data: any): void => {
    if (data.code === 200) {
      const loginState = data.data.state;
      console.log('登录状态: ', loginState);

      if (loginState) return;
      localStorage.clear();
      sessionStorage.clear();
      Dialog.alert({
        message: '登陆超时，请先登录！',
      }).then(() => router.push('/Login'));
    }
  };
  onMounted(() => {
    const params = { utk: localStorage.getItem('utk') || '' };
    socket.emit('checkUtk', params, checkResult);
  });
};
