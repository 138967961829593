import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bc6e2f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatFrame" }
const _hoisted_2 = { ref: "endMsgRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GradientBox = _resolveComponent("GradientBox")!
  const _component_msgBubble = _resolveComponent("msgBubble")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GradientBox),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.msgData, (msg) => {
        return (_openBlock(), _createBlock(_component_msgBubble, {
          key: msg.msgId,
          userId: msg.userId,
          userName: msg.userName,
          avatar: msg.avatar,
          msg: msg.content,
          msgTime: msg.msgTime,
          msgType: msg.msgType,
          sendState: msg.sendState,
          homeowner: msg.homeowner
        }, null, 8, ["userId", "userName", "avatar", "msg", "msgTime", "msgType", "sendState", "homeowner"]))
      }), 128))
    ], 512)
  ]))
}