
import CryptoJs from 'crypto-js';
import { Toast } from 'vant';
import { reactive, toRefs, inject } from 'vue';
import router from '@/router';
export default {
  name: 'Login',
  setup(): any {
    let data = reactive({
      accountName: '',
      password: '',
      loginToast: null,
    });
    // 接收socket
    const socket: any = inject('socket');
    // 登录
    const onSubmit = (data: any): void => {
      // 后台请求
      const params = { accountName: data.accountName, password: CryptoJs.MD5(data.password).toString() };
      socket.emit('login', params, loginSuccess);
      data.loginToast = Toast.loading({
        duration: 0,
        forbidClick: true, // 禁止单击
        message: '登陆中...',
      });
    };
    // 成功登录，获取用户数据
    const loginSuccess = (data: any) => {
      // 清除弹窗
      Toast.clear();
      data.loginToast = null;
      if (data.code === 200) {
        const userInfo: any = data.data;
        sessionStorage.setItem('userId', userInfo.userId);
        sessionStorage.setItem('userName', userInfo.userName);
        sessionStorage.setItem('avatar', userInfo.avatar);
        localStorage.setItem('utk', userInfo.utk);
        router.push('Home');
        Toast('您已成功登录！');
      } else {
        Toast(`登录失败： ${data.msg}`);
      }
    };
    // 返回
    const goBack = (): void => {
      router.back();
    };
    // qq / 微信 登录
    const otherLogin = (): void => {
      Toast('功能暂未开放');
    };
    return { ...toRefs(data), onSubmit, goBack, otherLogin };
  },
};
