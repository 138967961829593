
import CryptoJs from 'crypto-js';
import { reactive, toRefs, inject } from 'vue';
import { Dialog, Toast } from 'vant';
import router from '@/router';
export default {
  name: 'ForgetPwd',
  setup() {
    // 接收socket
    const socket: any = inject('socket');
    let data = reactive({
      accountName: '',
      newPassword: '',
      verificationCode: '',
      emailSendState: 0, // 邮件发送状态，0. 未请求 1. 请求发送 2. 请求成功回调
      emailTip: '发送验证码', // 邮件发送按钮内容
    });
    // 保存修改
    const onSubmitPwd = (params: any): void => {
      params['newPassword'] = CryptoJs.MD5(params.newPassword).toString();
      socket.emit('forgetPwd', params, setAccountSuccess);
      Toast.loading({
        duration: 0,
        forbidClick: true, // 禁止单击
        message: '正在修改...',
      });
    };
    const setAccountSuccess = (data: any): void => {
      Toast.clear();
      Dialog.alert({
        title: data.code === 200 ? '成功' : '失败',
        message: data.msg,
      }).then(() => {
        data.code === 200 && router.push('Login');
      });
    };

    // 发送邮件验证
    const authEmail = (): void => {
      if (!data.accountName) {
        Toast('邮箱信息有误！');
        return;
      }
      data.emailSendState = 1; // 邮箱验证请求发送中
      socket.emit('authEmail', { email: data.accountName }, sendEmailResult);
    };
    // 邮件发送回调
    const sendEmailResult = (data: any): void => {
      emailTimer(60); // 邮箱验证定时器
      Toast(data.msg); // 回调通知
    };
    // 邮件验证定时器
    const emailTimer = (second: number): void => {
      data.emailSendState = 2; // 邮件发送请求成功回调
      const timer: any = setInterval(() => {
        data.emailTip = second.toString() + 's' + ' 后可重新发送';
        if (second <= 0) {
          clearInterval(timer);
          data.emailTip = '发送邮件';
          data.emailSendState = 0;
        }
        second--;
      }, 1000);
    };

    // 返回
    const goBack = (): void => {
      router.back();
    };
    return {
      ...toRefs(data),
      authEmail,
      onSubmitPwd,
      goBack,
    };
  },
};
