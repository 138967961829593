
import { reactive, toRefs, inject, onMounted } from 'vue';
import { Dialog, Toast } from 'vant';
import router from '@/router';
import ImgUploader from '@/components/ImgUploader/index.vue';
export default {
  name: 'SetPersonalData',
  components: { ImgUploader },
  setup() {
    // 接收socket
    const socket: any = inject('socket');
    let data = reactive({
      userName: '',
      imgList: [], // 头像列表
    });
    // 保存修改
    const onSubmit = (params: any): void => {
      const imgFile: any = data.imgList[0];
      params.avatar = imgFile?.content || ''; // 头像
      params['userId'] = sessionStorage.getItem('userId') || ''; // 用户id
      socket.emit('setAccount', params, setAccountSuccess);
      Toast.loading({
        duration: 0,
        forbidClick: true, // 禁止单击
        message: '正在修改...',
      });
    };
    // 修改信息成功回调
    const setAccountSuccess = (data: any): void => {
      Toast.clear();
      Dialog.alert({
        title: data.code === 200 ? '成功' : '失败',
        message: data.msg,
      }).then(() => {
        // 用户绑定sid信息
        const utk = localStorage.getItem('utk');
        if (utk) {
          socket.emit('userConnect', { utk }, (data: any) => {
            console.log(data);
            const userInfo = data.data.userInfo;
            sessionStorage.setItem('userId', userInfo.userId || '');
            sessionStorage.setItem('userName', userInfo.userName || '');
            sessionStorage.setItem('avatar', userInfo.avatar || '');
            sessionStorage.setItem('roomId', userInfo.roomId || '');
            sessionStorage.setItem('homeowner', userInfo.homeowner || '');
            sessionStorage.setItem('roomPwd', data.data.roomPwd);
            router.back();
          });
        }
      });
    };

    // 设置图片列表数据
    const setImgList = (imgList: []): void => {
      data.imgList = imgList;
    };
    // 初始化
    onMounted(() => {
      data.userName = sessionStorage.getItem('userName') || '';
    });

    return {
      ...toRefs(data),
      onSubmit,
      setImgList,
    };
  },
};
