
import { inject } from 'vue';
import { Dialog, Toast } from 'vant';
export default {
  name: 'AccountCellGroup',
  setup(): any {
    // 接收socket
    const socket: any = inject('socket');
    // 退出登录
    const logout = (): void => {
      Dialog.confirm({
        message: '您确定要退出登录吗？',
      })
        .then(() => socket.emit('logout'))
        .catch(() => {});
    };
    const logoff = (): void => {
      Toast('功能暂未开放');
    };
    return { logout, logoff };
  },
};
