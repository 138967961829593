
import { computed } from 'vue';
export default {
  name: 'msgBubble',
  props: {
    userId: {
      type: String,
      required: true,
    },
    userName: {
      type: String,
      required: true,
    },
    msgTime: {
      type: String,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
    msgType: {
      type: Boolean,
      default: true,
    },
    sendState: {
      type: Boolean,
      required: true,
    },
    homeowner: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: { [key: string]: any }): any {
    const sendStateCache = computed((): boolean => props.sendState);
    return { sendStateCache };
  },
};
