
import CellGroup from '@/components/CellGroup/index.vue';
import UserInfoCell from './components/UserInfoCell/index.vue';
import AccountCellGroup from './components/AccountGroup/index.vue';
import { toolGroup } from './cellGroups';
export default {
  name: 'PersonCenterHome',
  components: { CellGroup, UserInfoCell, AccountCellGroup },
  setup(): any {
    return { toolGroup };
  },
};
