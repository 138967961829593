
export default {
  name: 'CellGroup',
  props: {
    title: {
      type: String,
      default: '',
    },
    cellList: {
      type: Array,
      default: (): any[] => [],
    },
  },
};
