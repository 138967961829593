
import { reactive, toRefs, inject, onMounted } from 'vue';
import router from '@/router';
import { Dialog, Toast } from 'vant';
export default {
  name: 'JoinRoom',
  setup(): any {
    let data = reactive({
      roomId: '',
      roomPwd: '',
      loginToast: null,
    });
    // 接收socket
    const socket: any = inject('socket');
    // 加入房间
    const joinRoom = (): void => {
      sessionStorage.removeItem('joinRoomInfo'); // 清空待加入房间信息
      const params = { roomId: parseInt(data.roomId), roomPwd: parseInt(data.roomPwd) };
      socket.emit('joinRoom', params, joinRoomSuccess);
    };
    // 创建/加入房间
    const onSubmit = (): void => {
      joinRoom(); // 后台请求，加入房间
    };
    // 成功加入房间回调
    const joinRoomSuccess = (msg: any): void => {
      console.log(msg);
      if (msg.code === 200) {
        // 设置用户房间信息
        const roomInfo = msg.data;
        sessionStorage.setItem('roomId', roomInfo.roomId);
        sessionStorage.setItem('roomPwd', roomInfo.roomPwd);
        sessionStorage.setItem('homeowner', roomInfo.homeowner);
        // 跳转播放页面
        router.push('VideoRoom');
      } else {
        Toast(msg.msg);
        data.roomId = '';
        data.roomPwd = '';
      }
    };
    // 返回
    const goBack = (): void => {
      router.back();
    };
    // 检测房间是否存在
    onMounted(() => {
      // 判断用户是否存在房间，房间重连
      sessionStorage.getItem('roomId') ? router.push('VideoRoom') : getHomeData();
    });

    // 获取房间数据
    const getHomeData = (): void => {
      const roomInfo: { roomId?: string; roomPwd?: string } = JSON.parse(
        sessionStorage.getItem('joinRoomInfo') || '{}'
      );
      data.roomId = roomInfo?.roomId || '';
      data.roomPwd = roomInfo?.roomPwd || '';
      if (data.roomId && data.roomPwd) {
        Dialog.confirm({
          message: `检测到您有房间（${data.roomId}）信息，是否直接加入？`,
        })
          .then(() => joinRoom())
          .catch(() => sessionStorage.removeItem('joinRoomInfo'));
      }
    };

    // 获取剪切板数据（房间数据），移动端H5页面不兼容
    // const getCopyData = (): void => {
    //   console.log(navigator);
    //   navigator.clipboard
    //     .readText()
    //     .then((copyValue) => {
    //       console.log('获取剪贴板成功：', copyValue);
    //       let roomInfo = copyValue.match('「房间号:(.+) 密码:(.+)」');
    //       data.roomId = roomInfo?.[1] || '';
    //       data.roomPwd = roomInfo?.[2] || '';
    //       if (data.roomId && data.roomPwd) {
    //         Dialog.confirm({
    //           message: '检测到您剪切板房间信息，是否直接加入？',
    //         })
    //           .then(() => joinRoom())
    //           .catch(() => copyToClipboard(' '));
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('获取剪贴板失败: ', err);
    //     });
    // };
    return { ...toRefs(data), onSubmit, goBack };
  },
};
