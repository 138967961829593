
import router from '@/router';
import OptionCard from '@/components/OptionCard/index.vue';
import useCheckUtk from '@/hooks/useCheckUtk';
export default {
  name: 'Home',
  components: {
    OptionCard,
  },
  setup(): any {
    // 登陆超时监测
    useCheckUtk();
    // 跳转页面
    const toPage = (pageName: string): void => {
      router.push(pageName);
    };
    return { toPage };
  },
};
