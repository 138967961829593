/*
 * @Description: 路由
 * @Author: hekang
 * @Date: 2022-02-25 13:42:56
 * @LastEditors: hekang
 * @LastEditTime: 2022-07-22 13:50:08
 */
import { Dialog, Toast } from 'vant';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
// 主页
import Welcome from '@/views/Welcome/index.vue';
import Home from '@/views/Home/index.vue';
// 用户授权
import Login from '@/views/UserAuth/Login/index.vue';
import Register from '@/views/UserAuth/Register/index.vue';
import ForgetPwd from '@/views/UserAuth/ForgetPwd/index.vue';
// 用户中心
import PersonalCenter from '../views/UserOption/PersonalCenter/index.vue';
import PersonalRecords from '../views/UserOption/PersonalRecords/index.vue';
import About from '@/views/UserOption/About/index.vue';
import ContactUs from '@/views/UserOption/ContactUs/index.vue';
import Feedback from '@/views/UserOption/Feedback/index.vue';
import SetNotification from '@/views/UserOption/SetNotification/index.vue';
import SetPermissions from '@/views/UserOption/SetPermissions/index.vue';
import SetPersonalData from '@/views/UserOption/SetPersonalData/index.vue';
import SetUserPwd from '@/views/UserOption/SetUserPwd/index.vue';
import PersonalCenterHome from '@/views/UserOption/PersonalCenterHome/index.vue';
// 功能模块
import TogetherHome from '@/views/ToolModule/TogetherVideo/TogetherHome/index.vue';
import JoinRoom from '@/views/ToolModule/TogetherVideo/JoinRoom/index.vue';
import VideoRoom from '@/views/ToolModule/TogetherVideo/VideoRoom/index.vue';
import MatchFriends from '@/views/ToolModule/MatchFriends/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/ForgetPwd',
    name: 'ForgetPwd',
    component: ForgetPwd,
  },
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: PersonalCenter,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'PersonalCenterHome',
        component: PersonalCenterHome,
      },
      {
        path: 'About',
        name: 'About',
        component: About,
      },
      {
        path: 'ContactUs',
        name: 'ContactUs',
        component: ContactUs,
      },
      {
        path: 'Feedback',
        name: 'Feedback',
        component: Feedback,
      },
      {
        path: 'SetNotification',
        name: 'SetNotification',
        component: SetNotification,
      },
      {
        path: 'SetPermissions',
        name: 'SetPermissions',
        component: SetPermissions,
      },
      {
        path: 'SetPersonalData',
        name: 'SetPersonalData',
        component: SetPersonalData,
      },
      {
        path: 'SetUserPwd',
        name: 'SetUserPwd',
        component: SetUserPwd,
      },
    ],
  },
  {
    path: '/PersonalRecords',
    name: 'PersonalRecords',
    component: PersonalRecords,
  },
  {
    path: '/VideoRoom',
    name: 'VideoRoom',
    component: VideoRoom,
    meta: { auth: true },
  },
  {
    path: '/TogetherHome',
    name: 'TogetherHome',
    component: TogetherHome,
    meta: { auth: true },
  },
  {
    path: '/JoinRoom',
    name: 'JoinRoom',
    component: JoinRoom,
    meta: { auth: true },
  },
  {
    path: '/MatchFriends',
    name: 'MatchFriends',
    component: MatchFriends,
    meta: { auth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 全局路由守卫
// 跳转前
router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    if (localStorage.getItem('utk')) {
      next();
    } else {
      Toast('您还未登录，请先登录！');
      router.push('/Login');
      // Dialog.confirm({
      //   message: '您还未登录，请先登录！',
      // })
      //   .then(() => router.push('/Login'))
      //   .catch(() => {});
    }
  } else {
    next();
  }
});

export default router;
