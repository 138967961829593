
import { defineComponent } from 'vue';
import { Toast } from 'vant';
import { reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'ImgUploader',
  components: {},
  props: {
    setImgList: {
      type: Function,
      default: (): any => {},
    },
  },
  setup(props) {
    let data = reactive({
      imgList: [], // 头像列表
    });
    // 用户头像图片类型限制
    const imgTypeRestriction = (file: any) => {
      if (['image/jpeg', 'image/png'].indexOf(file.file.type) == -1) {
        Toast('请上传 jpg/png 格式图片');
        data.imgList = [];
        props.setImgList?.([]);
        return false;
      }
      props.setImgList?.(data.imgList);
      return true;
    };
    // 用户头像图片大小控制
    const imgOversize = () => {
      Toast('文件大小不能超过 1m');
    };
    return { ...toRefs(data), imgTypeRestriction, imgOversize };
  },
});
