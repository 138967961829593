import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ad0a62a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "videoFrame" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyVideoPlayer = _resolveComponent("MyVideoPlayer")!
  const _component_ChatBox = _resolveComponent("ChatBox")!
  const _component_MsgInputBox = _resolveComponent("MsgInputBox")!
  const _component_RoomTools = _resolveComponent("RoomTools")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MyVideoPlayer)
    ]),
    _createVNode(_component_ChatBox, { msgData: $setup.msgData }, null, 8, ["msgData"]),
    _createVNode(_component_MsgInputBox),
    _createVNode(_component_RoomTools)
  ]))
}